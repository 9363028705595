import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchOrder, resetMessage, sendNote } from '../contexts/app.actions';
import KegProduct from '../components/KegProduct';
import moment from 'moment';
import ModalImage from 'react-modal-image';
import _ from 'lodash';
import { motion } from 'framer-motion';

const CurrentOrder = ({ appDispatch, order, loading, message }) => {
  let { abraId } = useParams();
  // TODO dynamic
  const [currentOrderSubIndex, setCurrentOrderSubIndex] = useState();
  const [currentOrderSub, setCurrentOrderSub] = useState();
  const [productViewed, setProductViewed] = useState();
  const [trackingUrl, setTrackingUrl] = useState('');
  const [helperDates, setHelperDates] = useState();
  const [showCollapsibleSubIndex, setShowCollapsibleSubIndex] = useState();
  const [showSendNoteMessage, setShowSendNoteMessage] = useState({
    show: false,
    success: 'Zpráva byla úspěšně odeslána!',
    failure: 'Při odesílání nastala chyba...'
  })
  const [emailForm, setEmailForm] = useState({
    email: '',
    message: '',
    subjectType: '',
  })
  const expandableSetting = {
    hidden: { opacity: 0, height: 0 },
    show: {
      opacity: 1,
      height: 'auto',
      transition: {
        staggerChildren: 0.5,
        type: 'spring',
        damping: 10,
        mass: 0.75,
        stiffness: 100,
      },
    },
  };

  const subjectTypes = {
    addressChange: 'Změna doručovací adresy',
    pushOrder: 'Odložit odeslání o týden',
    other: 'Jiný důvod',
  };

  useEffect(() => abraId && fetchOrder(appDispatch, abraId), [abraId]);
  useEffect(() => {
    if (order) {
      console.log(order)
      const orderSubs = order.orderSubs;
      const firstOrderSub = _.find(orderSubs, { index: 1 });
      const helperDatesObj = {};
      orderSubs?.length &&
        orderSubs.forEach((item) => {
          // Precedence: item.sendOn > calculated from first orderSub > null
          helperDatesObj[`sendOn_${item.index}`] = item.sendOn
            ? moment(item.sendOn).format('DD.MM.YYYY')
            : firstOrderSub?.sendOn
            ? moment(firstOrderSub.sendOn)
                .add(order.sourceOrderSub.frequency * item.index, 'days')
                .format('DD.MM.YYYY')
            : 'N/A';
        });
      setHelperDates(helperDatesObj);
      setEmailForm({
        email: order.order.ordersDomain.orderEmail,
        message: '',
        subjectType: subjectTypes.addressChange
      })
    }


    return () => {
      setHelperDates();
    };
  }, [order]);

  const onEmailFormChange = (e, type) => {
    setEmailForm({
      ...emailForm,
      [type]: e.target.value
    })
  }

  useEffect(() => {
    if (helperDates) {
      let currentOrderIndex = 1;
      if (order?.sourceOrderSub && order?.orderSubs?.length) {
        // Only concerns balikobot orders, not personal pickup
        // for (let i = order.orderSubs.length; i > 0; i--) {
        //   if (
        //     order?.orderSubsByIndex?.[i]?.sendOn &&
        //     order?.balikobotOrdersByOrderSubIndex?.[i]
        //   ) {
        //     currentOrderIndex = i;
        //     break;
        //   }
        // } 
        for (let orderSub of order?.orderSubs) {
          const statusLogs = order?.statusLogsByOrderSubIndex[orderSub.index];
          // Předána dopravcem zákazníkovi a Vydaná zákazníkovi
          const deliveredLog = _.find(statusLogs, log => log.status === 'H900000101' || log.status ===  'O900000101'); 
          // If has sendOn and was not delivered or picked up, set as such a first case as the current order
          if (orderSub.sendOn && !deliveredLog) {
            currentOrderIndex = orderSub.index;
            break;
          }
        }
      }
      setCurrentOrderSubIndex(currentOrderIndex);
    }
  }, [helperDates]);

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  useEffect(() => {
    if (currentOrderSubIndex && order) {
      // console.log(order)
      setCurrentOrderSub(order?.orderSubsByIndex?.[currentOrderSubIndex]);
      // const statusLogs = order?.statusLogsByOrderSubIndex[currentOrderSubIndex];
      // const deliveredLog = _.find(statusLogs, { status: 'H900000101' }); // Předána dopravcem zákazníkovi
      // setHasBeenDelivered(deliveredLog ? 'ANO' : 'NE');
      const balikobotOrder =
        order?.balikobotOrdersByOrderSubIndex?.[currentOrderSubIndex];
      setTrackingUrl(
        balikobotOrder?.trackUrl
          ? balikobotOrder.trackUrl
          : 'Odkaz bude doplněn později'
      );
    }
    // console.log(currentOrderSubIndex);
  }, [currentOrderSubIndex, order]);

  useEffect(() => {
    // Both
    if (message && (
      (message === 'NOTE_SEND_SUCCESS') || 
      (message === 'NOTE_SEND_FAILURE') 
    )) {
      setShowSendNoteMessage(preVal => ({
        ...preVal,
        show: true,
      }))
      setTimeout(
        () => {
          setShowSendNoteMessage(preVal => ({
            ...preVal,
            show: false,
          }))
          resetMessage()
        }, 2000)
    }

    // Success case only 
    if (message && (message === 'NOTE_SEND_SUCCESS')) {
      setEmailForm({
        email: order.order.ordersDomain.orderEmail,
        message: '',
        subjectType: subjectTypes.addressChange
      })
    }

  }, [message])

  const onProductClicked = (productId) => {
    setProductViewed(productViewed === productId ? null : productId);
  };

  const onSendNote = () => {
    const note = emailForm.subjectType + ' // ' + emailForm.email + ' // ' + emailForm.message
    sendNote(
      appDispatch, 
      // Source order
      order?.order?.ordersDomain?.abraId, 
      // Suborder
      currentOrderSubIndex, 
      note,
    )
  }

  const getNormalitedTransport = airtoyTransName => {
    if (airtoyTransName) {
      switch (true) {
        case airtoyTransName.includes('WeDo'):
          return 'WeDo';
        case airtoyTransName.includes('Zásilkovna'):
          return 'Zásilkovna';
        case airtoyTransName.includes('Osobní odběr'):
          return 'Osobní odběr';
        default:
          return '--';
      }
    }
  }

  return (
    <div id="content-container">
      {abraId && order ? (
        <div style={{ padding: '3.5rem 2rem' }}>
          <h2 style={{ color: '#f87227', marginBottom: 0 }} className="z-index-101">
            { order?.subscription?.name}
          </h2>
          {currentOrderSub && (
            <div className="">
              {currentOrderSub?.ordersDomain?.items[0]?.commodityDomain?.path && (
                <img
                  src={'https://img.beershop.cz' + currentOrderSub?.ordersDomain?.items[0]?.commodityDomain?.path}
                  // large={'https://img.beershop.cz' + currentOrderSub?.ordersDomain?.items[0]?.commodityDomain?.path}
                  className="photo-title"
                  // hideDownload={true}
                  // hideZoom={true}
                  alt=""
                  style={{ maxWidth: '70%' }}
                />
              )}
              <h2 style={{ color: '#f87227', paddingBottom: '0.3rem', paddingTop: '1.2rem' }}>
                Aktuální zásilka ({currentOrderSubIndex})
              </h2>

              <div className="order-info">
                <strong>Datum odeslání: </strong>{' '}
                {currentOrderSub.sendOn
                  ? moment(currentOrderSub.sendOn).format('DD.MM.YYYY')
                  : 'N/A'}
              </div>
              {/* <div className="order-info">
                <strong>Doručena: </strong> {hasBeenDelivered}
                <br />
              </div> */}
              { currentOrderSub?.ordersDomain?.transportationtypeId !== '00000O1000' && // Osobníodběry Praha a Brno
                currentOrderSub?.ordersDomain?.transportationtypeId !== '1310000101' ? (
                <>
                  <div className="order-info">
                    <strong>Sledování zásilky: </strong> { trackingUrl && isValidHttpUrl(trackingUrl) ? (
                      <a href={trackingUrl} target="_blank">{trackingUrl}</a>
                    ) : trackingUrl }
                    <br />
                  </div>
                  <div className="order-info">
                    <strong>Typ dopravy: </strong>{' '}
                    { getNormalitedTransport( 
                      order?.transportationTypesByAbraId?.[
                        order?.orderDomainsByOrderSubIndex?.[currentOrderSubIndex]?.transportationtypeId
                      ]?.name
                    )}
                  </div>
                </>
              ) : ''}

        

            </div>
          )}


          { helperDates && currentOrderSubIndex && currentOrderSubIndex < 6 ? (
            <>
            <h2 style={{ color: '#f87227', paddingTop: '1rem' }} className="z-index-fix">
              Příští zásilka
            </h2>
            <div className="z-index-fix">
              <strong>Odeslání { currentOrderSubIndex + 1}. zásilky:</strong>{' '}{ helperDates?.[`sendOn_${currentOrderSubIndex + 1}`]}
            </div>
          </>
          ) : ''}

          
          { order?.orderSubs?.length && order?.orderSubs.slice(0, currentOrderSubIndex - 1)?.length ?
          <>
            <h2 style={{ color: '#f87227', paddingTop: '1rem' }}>
              Předchozí zásilky
            </h2>

              <div style={{ margin: 0, padding: 0 }}>
                {order?.orderSubs?.length &&
                  order?.orderSubs
                    .slice(0, currentOrderSubIndex - 1)
                    .map((item, i) => (
                      <div style={{paddingBottom: '0.5rem'}}>
                        <strong>{ item.index}. zásilka doručena:</strong>{' '}{ item?.ordersDomain?.dateDelivered ? moment(item?.ordersDomain?.dateDelivered ).format('DD.MM.YYYY') : 'N/A'}
                      </div>
                    ))}
              </div>
          </>
          : ''}


          <h2 style={{ color: '#f87227', paddingTop: '1rem' }} className="z-index-fix">
            Doručovací adresa
          </h2>
          <div className="z-index-fix">
            { order?.sourceOrderSub?.recipient }{', '}
            { order.sourceOrderSub.shortAddress }
          </div>

          <h2 style={{ color: '#f87227', paddingTop: '1rem' }} className="z-index-fix">
            Poslat vzkaz Beershopu
          </h2>
          <div className="fcf-body z-index-fix">
            <div id="fcf-form">
              <div id="fcf-form-id" className="fcf-form-className">
                <div className="fcf-form-group">
                  <label for="Email" className="fcf-label">
                    Váš email
                  </label>
                  <div className="fcf-input-group">
                    <input
                      type="email"
                      id="Email"
                      name="Email"
                      className="fcf-form-control"
                      value={emailForm?.email}
                      onChange={e => onEmailFormChange(e, 'email')}
                      required
                    />
                  </div>
                </div>
                <div className="fcf-form-group">
                  <label for="select" className="fcf-label">
                    Zpráva se týká
                  </label>
                  <div className="fcf-input-group">
                    <select
                      id="select"
                      name="select"
                      className="fcf-form-control"
                      value={emailForm?.subjectType}
                      onChange={e => onEmailFormChange(e, 'subjectType')}
                      required
                    >
                      {Object.keys(subjectTypes).map((key, i) => (
                        <option key={i} id={key}>
                          {subjectTypes[key]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="fcf-form-group">
                  <label for="Message" className="fcf-label">
                    Zpráva
                  </label>
                  <div className="fcf-input-group">
                    <textarea
                      id="Message"
                      name="Message"
                      className="fcf-form-control"
                      rows="6"
                      maxLength="3000"
                      value={emailForm?.message}
                      onChange={e => onEmailFormChange(e, 'message')}
                      required
                    ></textarea>
                  </div>
                </div>

                <div className="fcf-form-group">
                  <button
                    id="fcf-button"
                    className="fcf-btn fcf-btn-primary fcf-btn-lg fcf-btn-block"
                    onClick={() => onSendNote()}
                  >
                    Odeslat
                  </button>
                  <div style={{paddingTop: '1rem'}}>
                    { message && showSendNoteMessage.show ? [
                      message === 'NOTE_SEND_SUCCESS' && (
                        <button className="message-button btn-success">
                          {showSendNoteMessage.success}
                        </button>
                      ),
                      message === 'NOTE_SEND_FAILURE' && (
                        <button className="message-button btn-danger">
                          {showSendNoteMessage.failure}
                        </button>
                      ),
                    ] : []}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : abraId && !order && !loading ? (
        <div style={{ padding: '3.5rem 2rem' }}>
          <div>Ke kódu {abraId} neexistuje objednávka...</div>
        </div>
      ) : (
        <div
          style={{
            marginTop: 50,
            paddingLeft: 10,
            textAlign: 'center',
            opacity: 0.5,
          }}
        >
          <div className="spinner" />
        </div>
      )}
    </div>
  );
};
export default CurrentOrder;
