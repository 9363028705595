import React from 'react'

const Homepage = () =>  {

    return (
        <div style={{ padding: '3.5rem 2rem'}}>
            <h1410 style={{color: '#f87227', paddingBottom: '1.5rem'}}>Web pro předplatitele</h1410>
        </div>
    )
}
export default Homepage
