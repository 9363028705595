 import * as actionTypes from './app.actionTypes';
//  kegSizesById munufacturers
 const appReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.KEGS_LIST_REQUEST:
        case actionTypes.VYCEPY_LIST_REQUEST:
        case actionTypes.BEERS_LIST_REQUEST:
        case actionTypes.COMMODITY_KEG_SIZES_LIST_REQUEST:
        case actionTypes.MANUFACTURERS_REQUEST:
        case actionTypes.ORDER_REQUEST:
        case actionTypes.SEND_NOTE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actionTypes.KEGS_LIST_RESPONSE:
            return {
                ...state,
                loading: false,
                kegs: action.payload
            };
        case actionTypes.ORDER_RESPONSE:
            return {
                ...state,
                loading: false,
                order: action.payload
            };
        case actionTypes.VYCEPY_LIST_RESPONSE:
            return {
                ...state,
                loading: false,
                vycepy: action.payload
            };
        case actionTypes.BEERS_LIST_RESPONSE:
            return {
                ...state,
                loading: false,
                beers: action.payload
            };
        case actionTypes.COMMODITY_KEG_SIZES_LIST_RESPONSE:
            return {
                ...state,
                loading: false,
                kegSizesById: action.payload
            };
        case actionTypes.SEND_NOTE_RESPONSE:
            return {
                ...state,
                loading: false,
                message: action.payload.message,
            };
        case actionTypes.MANUFACTURERS_RESPONSE:
            return {
                ...state,
                loading: false,
                manufacturers: {
                    ...state.manufacturers,
                    ...action.payload
                }
            };
        case actionTypes.KEGS_LIST_FAILURE:
        case actionTypes.VYCEPY_LIST_FAILURE:
        case actionTypes.BEERS_LIST_FAILURE:
        case actionTypes.COMMODITY_KEG_SIZES_LIST_FAILURE:
        case actionTypes.MANUFACTURERS_FAILURE:
        case actionTypes.ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case actionTypes.SEND_NOTE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.err,
                message: action.payload.message,
            };
        case actionTypes.MESSAGE_RESET:
            return {
                ...state,
                message: null,
            };
        default:
            return state;
    }
  };

export default appReducer
  