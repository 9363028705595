import './scss/style.scss';
import React, { useEffect } from 'react'
import Header from './layout/Header'
import Content from './layout/Content'
import { useAppState } from './contexts/app.state'
// import { fetchProducts, fetchBeers, fetcKegSizesList, fetchManufacturers } from './contexts/app.actions'

function App() {
  const [appState, appDispatch] = useAppState();
  const { order, loading, message } = appState;

  useEffect(() => {
  }, [])


  return (
      <div id="layout">
        <Header />
        <Content 
          appDispatch={appDispatch}
          order={order}
          loading={loading}
          message={message}
          // kegs={kegs} 
          // vycepy={vycepy}
          // beers={beers} 
          // kegSizesById={kegSizesById} 
          // allManufacturers={manufacturers}
        />
      </div>
  );
}

export default App;
