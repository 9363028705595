import React from 'react'
import {
    Switch,
    Route,
  } from 'react-router-dom'
import Kegs from '../pages/Kegs'
import Shop from '../pages/Shop'
import Vycepy from '../pages/Vycepy'
import Instructions from '../pages/Instructions'
import CurrentOrder from '../pages/CurrentOrder'
import Homepage from '../pages/Homepage'

const Content = (props) => {

    return (
        <div className="container">
            <main>
                <Switch>
                    <Route path="/:abraId" exact={true}> 
                        <CurrentOrder {...props} />
                    </Route>
                    <Route path="/" exact={true}> 
                        <Homepage {...props} />
                    </Route>
                </Switch>        
            </main>
        </div>
    )
}

export default Content
